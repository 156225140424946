<template>
  <div class="salesindex">
    <!-- @TODO: -->
    <!-- <div class="form-row my-3">
      <div v-for="(card, idx) in CARD_TOTAL" :key="idx + 'c'" class="col-md-4">
        <a-card bordered>
          <div class="d-flex align-items-center">
            <img
              class="my-auto rounded-circle shadow flexno p-2"
              :src="card.imgSrc"
              height="70"
              width="70"
              draggable="false"
            >
            <div class="col">
              <div class="pbz-font body-sm-regular">
                {{ $t('order.' + card.title) }}
              </div>
              <h3 class="mb-0 font-weight-bold">
                {{ card.total }}
              </h3>
            </div>
          </div>
        </a-card>
      </div>
    </div> -->
    <div class="position-relative">
      <div class="position-absolute mt-2 pbz-font body-md-bold">
        {{ $t('sideBar.sales') }}
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <a-input-search
          class="col-4"
          allow-clear
          size="large"
          :placeholder="$t('utils.search') + ' No Order'"
          :value="querySearch"
          :disabled="loadingList"
          @change="handleSearch"
        />
        <a-button
          size="large"
          icon="filter"
          class="ml-2"
          :disabled="loadingList"
          @click="onShowModalFilter"
        >
          {{ $t('utils.filter') }}
        </a-button>
      </div>
    </div>
    <!-- v-if="isFiltered" -->
    <div class="d-flex flex-wrap justify-content-center mb-2 mt-3 categories">
      <div
        v-for="(item, itemIdx) in activeFilter"
        :key="item + '' + itemIdx"
        class="pbz-font overline-medium mb-1 mr-1 rounded-pill list active"
      >
        {{ item }}
      </div>
      <a-button v-if="isFiltered" class="mb-1" @click="resetFilter">
        {{ $t('utils.clearFilter') }}
      </a-button>
    </div>

    <div class="d-flex justify-content-between align-items-start my-3">
      <!-- v-if="salesData.length || (!salesData.length && ($route.query.sales_state && $route.query.sales_state !== 'all'))" -->
      <div class="flex-grow-1">
        <a-button
          v-for="item in categoryState"
          :key="item.title"
          shape="round"
          :disabled="loadingList || activeCategories === item.title"
          :class="[
            'px-2 mb-1 mr-1',
            activeCategories === item.title && 'bg-white text-danger border-danger',
          ]"
          @click="changeCategorieState(item)"
        >
          {{ $t('order.filterState.' + item.title) }}
        </a-button>
      </div>

      <div class="d-flex align-items-center flexno">
        <b class="mr-2">
          {{ $t('order.sortBy') }}
        </b>
        <a-select
          style="width: 120px"
          :disabled="loadingList || !salesData.length"
          :value="sort_mode"
          @change="handleChangeSort"
        >
          <a-select-option v-for="sort in SORT_OPTIONS" :key="sort.value" :value="sort.value">
            {{ $t('order.' + sort.label) }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="d-flex justify-start align-items-center mt-4 pb-3" style="gap: 10px">
      <!-- <a-button
        type="primary"
        :disabled="loadingList && $store.state.exportData.exportFileNotification"
        @click.prevent="showModalExportExcel = true"
      >
        {{ $t('warehouse.export_data') }}
      </a-button>
      <a-button
        v-if="isGalery24"
        type="primary"
        :disabled="loadingList && $store.state.exportData.exportFileNotification"
        @click.prevent="toggleModalImport(true)"
      >
        Import Data
      </a-button>
      <a-button
        v-if="$route.query.sales_channel_id"
        type="primary"
        :disabled="loadingList && $store.state.exportData.exportFileNotification"
        @click.prevent="$router.push({ path: `/manual-order/${$route.params.id}/create`, query: $route.query })"
      >
        Create Order
      </a-button> -->
      <a-dropdown>
        <a-menu slot="overlay">
          <a-menu-item
            key="1"
            :disabled="loadingList && $store.state.exportData.exportFileNotification"
            @click="$router.push({ path: `/manual-order/${$route.params.id}/create`, query: $route.query })"
          >
            Create Single Order
          </a-menu-item>
          <a-menu-item
            key="2"
            :disabled="loadingList && $store.state.exportData.exportFileNotification"
            @click="toggleModalImport(true)"
          >
            Create Bulk Order
          </a-menu-item>
          <a-menu-item
            key="3"
            :disabled="loadingList && $store.state.exportData.exportFileNotification"
            @click="showModalExportExcel = true"
          >
            {{ $t('warehouse.export_data') }}
          </a-menu-item>
        </a-menu>
        <a-button type="primary">
          Export / Import <a-icon type="down" />
        </a-button>
      </a-dropdown>
      <a-button
        v-if="$route.meta.key.includes('sales-channel-kino') && tempSales.length"
        type="primary"
        class="ml-3"
        :disabled="loadingList"
        @click.prevent="handleSubmitSFA"
      >
        Resend SFA
      </a-button>
      <div class="ml-auto">
        <a-skeleton
          active
          :title="false"
          :loading="loadingList"
          :paragraph="{ rows: 1, width: 180 }"
          class="w-auto mb-0 skeleton-total-sales"
        >
          <h6>{{ $t('order.totalAllSales') }} {{ total_row }}</h6>
        </a-skeleton>
      </div>
    </div>

    <div class="table-responsive pb-2 mt-3" :style="{ maxHeight: screenHeight + 'px' }">
      <div
        class="container-fluid border rounded py-2 mx-0 mt-0"
        :style="{ minWidth: listWidth + 'px', position: 'sticky', top: 0, background: 'white', zIndex: 2 }"
      >
        <div class="row flex-nowrap row-calc">
          <div
            v-for="tr in trList"
            :key="tr"
            :class="[tr === 'orderNumber' ? orderCol : tr === 'itemTotal' ? 'col-10p' : 'col', 'text-truncate', tr === 'checkbox' ? 'w-8' : '']"
            style="font-size: 12px"
          >
            <a-checkbox
              v-if="$route.meta.key.includes('sales-channel-kino') && tr === 'checkbox' && total_box > 0"
              :indeterminate="indeterminate"
              :checked="checked_all"
              :disabled="loadingList"
              class="sales-checkbox"
              @change="checkboxSelectAllSubmitted"
            />
            <span v-else-if="tr !== 'checkbox'">
              {{ $t('order.' + tr) }}
            </span>
          </div>
          <div class="col px-1 text-truncate" style="font-size: 12px">
            Status
          </div>
          <div class="" style="width: 40px !important; position: sticky; right: 0; background: #fafafa;">
            &nbsp;
          </div>
        </div>
      </div>

      <LoadingListTable v-if="loadingList" class="my-2" />
      <template v-else>
        <ErrorInfo v-if="errorRows" :error-type="errorRows" />
        <template v-else>
          <template v-if="salesData.length">
            <List
              v-for="(list, index) in salesData"
              :key="list.id"
              :sales="list"
              :sales-num="index"
              :width="listWidth"
              :permission="permission"
              :order-length="orderLength"
              :temp="tempSales"
              class="mt-2"
              @onLogTracking="handleLogTracking"
              @handleItemSubmitted="handleItemSubmitted"
              @handleSfaDetail="handleSfaDetail"
              @onClickCancelOrder="onClickCancelOrder"
              @onClickAccepted="onClickAccepted"
              @onOpenCollapse="onOpenCollapse"
              @onCancelRequest="onCancelRequest"
            />
          </template>
          <!-- img-src="https://ik.imagekit.io/powerbiz/img/admin-saas/Icons.svg?updatedAt=1640182139911" -->
          <EmptyInfo
            v-else
            class="py-5"
            :title="$t('order.empty_title')"
            :description="$t('order.empty_msg')"
          />
        </template>
      </template>
    </div>
    <div class="d-flex justify-start align-items-center mt-3">
      <Pagination
        v-if="!loadingList && salesData.length"
        class="text-right ml-auto"
        :total="total_row"
        :page-size="PAGE_SIZE"
        :total-length-per-page="salesData.length"
        :default-page="currentPage"
        @changePage="changePage"
      />
    </div>

    <a-modal
      :closable="false"
      :footer="null"
      :dialog-style="{ top: '15px' }"
      :visible="showModalFilter"
      @cancel="showModalFilter = false"
    >
      <FilterList
        ref="filterModal"
        :order-period="orderPeriod"
        :load-warehouse="loadWarehouse"
        :load-channel="loadChannel"
        :options-channel="options_channel"
        :business-value="businessValue"
        :channel-value="channelValue"
        :warehouse-value="warehouseValue"
        :customer-value="customerValue"
        :courier-value="courierValue"
        @close="showModalFilter = false"
        @reset="resetFilter"
        @onFocusChannel="fetchChannel"
        @onBlurChannel="blurChannel"
        @onFocusWarehouse="fetchWarehouse"
        @onBlurWarehouse="onBlurWarehouse"
      />
    </a-modal>

    <ModalConfirm
      :visible="showModalAccepted"
      :loading="loadingAccept"
      icon="check-circle"
      :title="$t('order.accept_confirm_msg')"
      :ok-label="$t('utils.yes')"
      :cancel-label="$t('utils.cancel')"
      @ok="onAcceptOrder"
      @cancel="onCloseModalAccept"
    >
      <div
        class="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon py-2 mb-2 text-left"
      >
        <b>{{ $t('order.orderNumber') }}</b> : {{ dataAccepted.order_number }}
      </div>
    </ModalConfirm>

    <ModalCancelOrder
      ref="refModalCancelOrder"
      :visible="showModalCancelOrder"
      :loading="loadingCancelOrder"
      :data="dataCancelOrder"
      @cancel="closeModalCancelOrder"
      @ok="onSubmitCancelOrder"
    />

    <ModalExportExcel
      :visible="showModalExportExcel"
      :on-close="onCloseModalExportExcel"
      :category-state="categoryState"
      :total-export-row-data="totalExportSalesData"
      @handleSubmitModal="exportDataTable($event, true)"
      @handleCheckTotalRowData="exportDataTable($event, false)"
    />

    <a-modal
      title="Detail SFA"
      :visible="sfaInfo.visible"
      :closable="false"
      :footer="null"
      :width="450"
    >
      <a-row :gutter="16">
        <a-col :span="8">
          {{ $t('order.orderNumber') }}
        </a-col>
        <a-col :span="16">
          : {{ sfaInfo.order_number }}
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt-2">
        <a-col :span="8">
          Status
        </a-col>
        <a-col :span="16">
          : {{ $t('utils.failed') }}
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt-2">
        <a-col :span="8">
          {{ $t('store_list_page.description') }}
        </a-col>
        <a-col :span="16">
          :
        </a-col>
        <a-col :span="24" class="mt-1">
          {{ sfaInfo.description }}
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt-4">
        <a-col :span="24" class="text-right">
          <a-button type="primary" ghost @click="sfaInfo.visible = false">
            {{ $t('utils.close') }}
          </a-button>
        </a-col>
      </a-row>
    </a-modal>

    <a-modal v-model="showModalImport" :width="380" :closable="false" title="Impor Manual Order">
      <template slot="footer">
        <a-button
          key="back"
          :disabled="downloading || uploading"
          @click="fileList = []; toggleModalImport(false)"
        >
          Kembali
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :style="isVerified ? 'background: #2196f3 !important; border-color: #2196f3 !important;' : ''"
          :loading="uploading"
          :disabled="fileList.length < 1 || downloading || uploading"
          @click="isVerified ? handleUpload() : handleVerification()"
        >
          {{ isVerified ? 'Upload' : 'Verifikasi' }} File
        </a-button>
      </template>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-spin :spinning="downloading">
            <button
              class="avatar-uploader ant-upload-picture-card-wrapper btn-download"
              :disabled="downloading || uploading"
              @click="downloadTemplate"
            >
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                <span role="button" tabindex="0" class="ant-upload">
                  <div>
                    <a-icon type="download" />
                    <div class="ant-upload-text">
                      Download Template
                    </div>
                  </div>
                </span>
              </div>
            </button>
          </a-spin>
        </a-col>
        <a-col :span="12">
          <a-spin :spinning="uploading">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :file-list="fileList"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              :disabled="downloading || uploading"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              <div v-if="fileList.length < 1">
                <a-icon :type="uploading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  Upload Bulk Manual Order
                </div>
              </div>
            </a-upload>
          </a-spin>
        </a-col>
      </a-row>
      <a-alert
        v-if="importFile.show"
        :message="`Verifikasi ${isEmptyFile || !isVerified ? 'Gagal' : 'Berhasil'}`"
        :type="isEmptyFile || !isVerified ? 'error' : 'success'"
        class="verified-alert mt-2"
      >
        <p slot="description" class="mb-0" style="font-size: 12px">
          <template v-if="isEmptyFile">
            Data kosong. <br />
            Mohon untuk mengisi data minimal 1 row untuk dapat melakukan proses Bulk Order.
          </template>
          <template v-else-if="isVerified">
            Semua data telah lolos verifikasi. <br />
            Silahkan klik tombol <b>Upload File</b> untuk melakukan proses Bulk Order.
          </template>
          <template v-else>
            Terdapat <b>{{ importFile.failed }}</b> baris data yg tidak lolos verifikasi. <br />
            Mohon untuk perbaiki data tersebut agar dapat melakukan proses Bulk Order. <br />
            NB: Cek kolom <b>Keterangan</b> (kolom terakhir)
            <b><a href="#" style="font-size: 13px; color: #1890ff !important" @click="downloadFailedData">Download Data Gagal</a></b>
          </template>
        </p>
      </a-alert>
    </a-modal>

    <a-modal
      v-model="showModalLog"
      :width="480"
      :closable="false"
      :title="`Status Pengiriman AWB ${shipmentLogTitle}`"
      centered
    >
      <a-skeleton v-if="loadingLog" :loading="loadingLog" active />
      <template v-else>
        <div v-if="!shipmentLogList.length" class="text-center">
          <p class="mb-2" style="font-size: 18px">
            <b>Data Pengiriman Tidak Ditemukan</b>
          </p>
          <p class="mb-0">
            Mohon untuk mencoba beberapa saat lagi atau silahkan hubungi Admin terkait kendala data pengiriman
          </p>
        </div>
        <template v-else>
          <a-timeline-item v-for="(log, index) in shipmentLogList" :key="index" :color="`${index == 0 ? 'red' : 'gray'}`" class="log-timeline">
            <p :class="['mb-1', index == 0 ? 'log-current' : '']">
              <b>{{ $moment(parseInt(shipmentLogList[shipmentLogList.length - index - 1]?.date) * 1000).format('DD MMMM YYYY HH:mm') }}</b>
            </p>
            <p class="mb-0">
              {{ shipmentLogList[shipmentLogList.length - index - 1]?.desc }}
            </p>
          </a-timeline-item>
        </template>
      </template>
      <template slot="footer">
        <div class="text-right">
          <a-button type="primary" @click="showModalLog = false; shipmentLogTitle = ''">
            OK
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getSales, downloadManualOrder, verifiedBulkOrder, bulkManualOrder } from '@/api/sales'
import { getShippingOrder, getShipmentLog } from '@/api/shipping'
import { getKinoSfa, resendKinoSfa } from '@/api/sfa'
import { processingOrder, processingOrderState, cancelOrderState } from '@/api/channels/index'
import FilterList from '@/components/Sales/Form/Filter/index.vue'
import List from '@/components/Sales/List/index.vue'
import ModalConfirm from '@/components/Modal/ModalConfirm.vue'
import ModalCancelOrder from '@/components/Sales/Modal/ModalCancelOrder'
import Pagination from '@/components/Pagination/index.vue'
import LoadingListTable from '@/components/Loading/ListTable'
import ErrorInfo from '@/components/ErrorInfo'
import EmptyInfo from '@/components/EmptyInfo'
import debounce from 'lodash/debounce'
import ChannelsConstants from '@/constants/channels'
import ModalExportExcel from '@/views/manual-order/ModalExportExcel.vue'
import XLSX from 'xlsx';
import { useCurrency } from '@/composable/useCurrency'
// import { is } from 'vee-validate/dist/rules'
const { format } = useCurrency()

const LIST_CHANNEL_OFFLINE = [
  "b2b_offline",
  "offline",
  "zalora_id",
  "external_web",
  "whatsapp_id",
  "shopify_id",
]
const setTableWidth = () => {
  const { width } = window.screen
  return width < 821 ? 1190 : width - 343
}

export default {
  components: {
    FilterList,
    List,
    ModalConfirm,
    ModalCancelOrder,
    LoadingListTable,
    EmptyInfo,
    ErrorInfo,
    Pagination,
    ModalExportExcel,
  },
  data() {
    return {
      /** @TODO */
      // CARD_TOTAL: [
      //   { imgSrc: ImgPenjualan, title: 'salesTitle', total: 'Rp. 0 K'},
      //   { imgSrc: ImgPengiriman, title: 'shippingCost', total: 'Rp. 0 K' },
      //   { imgSrc: ImgItemTerjual, title: 'itemsSold', total: 'Rp. 0 K' },
      // ],
      SORT_OPTIONS: [
        { value: 'DESC', label: 'sortByNewest' },
        { value: 'ASC', label: 'sortByOldest' },
      ],
      categoryState: [
        { value: 'all', title: 'all' },
        { value: 'VERIFIED', title: 'verified' },
        { value: 'SUBMITTED', title: 'new' }, // old CREATED
        // { value: 'CANCELREQUESTED', title: 'cancelrequested' },
        // { value: 'VERIFIED', title: 'Confirmed' },
        { value: 'ACCEPTED', title: 'onprocess', shipping_states: 'CREATED' },
        { value: 'ACCEPTED', title: 'shipped', shipping_states: 'SHIPPED' }, // value: 'SHIPPED',
        // { value: 'in_packing', title: 'In Process' },
        { value: 'ACCEPTED', title: 'send', shipping_states: 'DELIVERED' }, // value: 'DELIVERED', | old COMPLETED
        { value: 'COMPLETED', title: 'completed' },
        { value: 'CANCELED,CANCELEDPAYMENTEXPIRED,REJECTED', title: 'canceled' }, // value: 'CANCELED' | 'CANCELLED'
        // { value: 'REJECTED', title: 'rejected' }, // Pembatalan pesanan
      ],
      PAGE_SIZE: 20,
      total_row: 0,
      total_box: 0,
      orderLength: 0,
      showModalFilter: false,
      id: [],
      warehouse_list: [],
      // business_list: [], // OPTION: if use service
      querySearch: '',
      errorRows: false,
      salesData: [],
      tempSales: [],
      loadingList: true,
      loadChannel: true,
      loadWarehouse: true,
      customerValue: '',
      courierValue: '',
      businessValue: [],
      channelValue: [],
      warehouseValue: [],
      orderPeriod: ['', ''],
      showModalCancelOrder: false,
      loadingCancelOrder: false,
      dataCancelOrder: {},
      dataAccepted: {},
      showModalAccepted: false,
      loadingAccept: false,
      controller: null,
      listWidth: setTableWidth(), // window.screen.width - 343, // 337
      showModalExportExcel: false,
      loadingExport: false,
      indeterminate: false,
      checked_all: false,
      sfaInfo: {
        visible: false,
        order_number: '',
        description: '',
      },
      totalExportSalesData: null,
      showModalImport: false,
      downloading: false,
      uploading: false,
      fileList: [],
      importFile: {
        show: false,
        success: 0,
        failed: 0,
        empty: 0,
        data: null,
      },
      loadingLog: false,
      showModalLog: false,
      shipmentLogTitle: '',
      shipmentLogList: [],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    trList() {
      const tr = ['orderDate', 'orderNumber', 'business', 'channel', 'storeName', 'customer', 'itemTotal', 'priceTotal']
      return this.$route.meta.key.includes('sales-channel-kino')
        ? ['checkbox'].concat(tr)
        : tr
    },
    locale() {
      return this.$store.state?.settings?.locale || 'id-ID'
    },
    channel() {
      return this.$store.state.channel
    },
    currentPage() {
      return +this.$route.query.page || 1
    },
    sort_mode() {
      return this.$route.query.sort_mode || 'DESC'
    },
    activeCategories() {
      const query = this.$route.query
      const salesState = query.filter_category // query.sales_state || query.shipping_states || 
      if (!salesState) return 'all'
      return this.categoryState.find(item => item.title === salesState)?.title || 'all'
    },
    options_channel() {
      return (this.$store.state?.channel?.saleschannel || []).map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    isFiltered() {
      const { path, query } = this.$route
      const {
        date_from,
        date_until,
        sales_channel_id,
        warehouse_id,
        business_id_sales,
        customer_name,
        courier_name,
      } = query
      const useWarehouse = this.getUserData.restriction_base !== 'Warehouse' && warehouse_id
      return !!(
        date_from ||
        date_until ||
        (path.includes('/manual-order') && sales_channel_id) ||
        useWarehouse ||
        business_id_sales ||
        customer_name ||
        courier_name
      )
    },
    activeFilter() {
      const {
        date_from,
        date_until,
        sales_channel_id,
        warehouse_id,
        business_id_sales,
        customer_name,
        courier_name,
      } = this.$route.query // courier_id
      let date = null,
        businessId = []
      if (date_from && date_until) {
        const formatDate = dateVal =>
          new Intl.DateTimeFormat(this.locale, { dateStyle: 'medium' }).format(dateVal)
        date = formatDate(new Date(date_from)) + ' - ' + formatDate(new Date(date_until))
      }

      if (business_id_sales) {
        const options_business = (this.$store.state?.user?.businessList || [])
          .filter(f => f.business_id !== 'all')
          .map(item => ({
            id: item.business_id,
            name: item.business_name || item.name,
          }))
        businessId = options_business.length
          ? [
              ...new Set(
                options_business
                  .filter(elem => business_id_sales.split(',').find(item => elem.id === item))
                  .map(item => item.name)
                  .join('^^')
                  .split('^^'),
              ),
            ]
          : []
      }

      const channelParam =
        this.$route.path.includes('/manual-order') && sales_channel_id ? String(sales_channel_id).split(',') : null
      const salesChannelId = channelParam
        ? this.channel.saleschannel
            .filter(elem => channelParam.find(item => elem.id === parseInt(item)))
            .map(item => item.name)
            .join('^^')
            .split('^^')
        : []
      // const warehouseParam = warehouse_id ? warehouse_id.split(',') : null
      // const warehouseId = warehouseParam ? this.warehouse_list.filter(elem => warehouseParam.find(item => elem.warehouse_id === item)).map(item => item.name).join('^^').split('^^') : []
      let warehouseId = []
      if (this.getUserData.restriction_base !== 'Warehouse') {
        if (warehouse_id) {
          warehouseId = this.warehouse_list
            .filter(elem => warehouse_id.split(',').find(item => elem.warehouse_id === item))
            .map(item => item.name)
            .join('^^')
            .split('^^')
        }
      }

      return [
        date,
        ...businessId,
        ...salesChannelId,
        ...warehouseId,
        customer_name,
        courier_name,
      ].filter(Boolean)
    },
    getUserData() {
      return this.$store.state?.user || {}
    },
    dataWarehouse() {
      return this.$store.state?.warehouse?.warehouse?.data || []
    },
    // shipping() { // ??
    //   return this.$store.state.shipping.shipping
    // },
    // customer() { // ??
    //   return this.$store.state.customer.customer
    // },
    // courier() { // ??
    //   return (this.$store.state?.shipping?.courier || []).map((item) => ({
    //     value: item.id,
    //     label: item.courier_name,
    //   }))
    // },
    // category() { // ??
    //   return (this.$store.state?.products?.categories || []).map((item) => ({
    //     value: item.id,
    //     label: item.name,
    //   }))
    // },
    // invoice() { // ??
    //   return this.$store.state.invoice.invoice
    // },
    screenHeight() {
      return ((screen.height - 900) * 0.53) + (screen.height * 0.53)
    },
    permission() {
      let key = this.$route.meta.key
      if(this.$route.meta.key.includes('-:id')) key = key.replace('-:id', '')
      return this.$store.getters['user/can'](key)
    },
    listChannels() {
      const channel = this.$store.state.business.listChannels.find(item => item.sales_channel?.code === 'distributor_redeem')
      return channel
    },
    orderCol() {
      return 'col'
      // return this.orderLength < 16
      //   ? 'col-2'
      //   : this.orderLength > 15 && this.orderLength < 20
      //   ? 'col-2'
      //   : 'col-3'
    },
    isEmptyFile() {
      return this.importFile.show && this.importFile.success < 1 && this.importFile.failed < 1 && this.importFile.empty > 0
    },
    isVerified() {
      return this.importFile.show && this.importFile.success > 0 && !this.importFile.data
    },
  },
  watch: {
    '$route.query'() {
      this.getData()
    },
    '$route.query.workspace_id'() {
      this.options_channel = []
      this.warehouse_list = []
      this.loadChannel = true
      this.loadWarehouse = true
      this.fetchChannel()
      this.fetchWarehouse()
    },
    listChannels() {
      this.getData()
    },
  },
  mounted() {
    this.getData()
    this.fetchWarehouse()
  },
  beforeDestroy() {
    // cancel the request
    if (this.controller) {
      this.controller.abort()
      this.controller = null
    }
  },
  methods: {
    async handleLogTracking(value) {
      const { channel_id, courier, awb_no } = value
      this.shipmentLogTitle = awb_no
      this.loadingLog = true
      this.showModalLog = true
      await getShipmentLog({
        channel_id,
        courier: courier?.toLowerCase(),
        awb_no,
      })
      .then(({ data: { data: response } }) => this.shipmentLogList = response?.[0]?.status || [])
      .catch(() => this.shipmentLogList = [])
      .finally(() => this.loadingLog = false)
    },
    resetImportData() {
      this.importFile = {
        show: false,
        success: 0,
        failed: 0,
        empty: 0,
        data: null,
      }
    },
    toggleModalImport(value) {
      this.showModalImport = value
      this.resetImportData()
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
      this.resetImportData()
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
      this.resetImportData()
      return false
    },
    async handleVerification() {
      this.uploading = true

      await verifiedBulkOrder({
        business_id: this.businessId,
        channel_id: this.$route.params.id,
        file: this.fileList[0],
      })
      .then(({ data }) => {
        this.importFile = {
          show: true,
          success: data?.success || 0,
          failed: data?.failed || 0,
          empty: data?.empty || 0,
          data: data?.data || null,
        }
      })
      .catch(err => {
        this.$notification.error({
          message: 'Verifikasi Gagal',
          description: err.response?.data?.message || err.message,
        })
      })
      .finally(() => this.uploading = false)
    },
    downloadFailedData() {
      try {
        const base64Excel = this.importFile.data
        const byteCharacters = atob(base64Excel)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = url
        a.download = 'Failed Order.xlsx'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (err) {
        this.$notification.error({
          message: 'Download Excel Gagal',
          description: err.response?.data?.message || err.message,
        })
      }
    },
    async downloadTemplate() {
      this.downloading = true

      await downloadManualOrder(this.businessId)
        .then(res => {
          if (res?.data) {
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(res.data)
            a.download = `Bulk Manual Order.xlsx`
            document.body.appendChild(a)
            a.click()
            setTimeout(() => {
              a.remove()
            }, 9)
          }
        })
        .catch(err => console.error(err))
        .finally(() => this.downloading = false)
    },
    async handleUpload() {
      this.uploading = true

      await bulkManualOrder({
        business_id: this.businessId,
        channel_id: this.$route.params.id,
        file: this.fileList[0],
      })
      .then(() => {
        this.fileList = []
        this.resetImportData()
        this.$notification.success({
          message: 'Upload Berhasil',
          description: 'Data berhasil di upload. Silahkan refresh data secara berkala',
        })
        this.getData()
      })
      .catch(err => {
        this.$notification.error({
          message: 'Upload Gagal',
          description: err.response?.data?.message || err.message,
        })
      })
      .finally(() => this.uploading = false)
    },
    wordingSalesState(sales_state) {
      if (!sales_state) {
        return null
      }
      switch (sales_state.toLowerCase()) {
        case 'verified':
          return 'verified'
        case 'submitted':
        case 'created':
          return 'new'
        case 'accepted':
          return 'onprocess'
        case 'shipped':
          return 'shipped'
        case 'delivered':
          return 'send'
        case 'completed':
          return 'completed'
        case 'canceled':
        case 'cancelled':
        case 'rejected':
        case 'canceledpaymentexpired':
          return 'canceled'
        case 'cancelrequested':
          return 'cancelrequested'
        default:
          return null
      }
    },
    async exportDataTable(data, isExport) {
      const { value, title, shipping_states } = data?.statusOrder
      const { path, query, params } = this.$route
      const { workspace_id, business_id, sales_channel_id } = query
      let salesChannelId = sales_channel_id
      let channelId = +params.id || undefined

      if (isExport) {
        const promises = []
        const filteredData = data
        const totalExportData = this.totalExportSalesData
        this.onCloseModalExportExcel()
        this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
          showModalExportSales: true,
          loadingExport: true,
        })
        for (let i = 0; i < Math.ceil(totalExportData / 100); i++) {
          const currentPage = i + 1
          const getDataSalesAndShipping = new Promise((resolve, reject) => {
            getSales({
              signal: undefined,
              params: {
                sales_state: value === 'all' || ['SHIPPED', 'DELIVERED'].includes(value) ? undefined : value,
                shipping_states: ['SHIPPED', 'DELIVERED', 'ACCEPTED'].includes(value) ? (shipping_states === 'DELIVERED' ? `${shipping_states},BUYER_ACCEPTED` : shipping_states) : undefined,
                filter_category: title,
                page: currentPage,
                limit: 100,
                sort_mode: 'DESC',
                workspace_id,
                business_id: filteredData?.selectedBusiness,
                sales_channel_id: filteredData?.selectedSalesChannelId?.join(','),
                channel_code: filteredData?.selectedSalesChannelCode,
                channel_id: channelId,
                date_from: filteredData?.startDate,
                date_until: filteredData?.endDate,
              },
            })
            .then(async ({ data: { data: resSales } }) => {
              const flattenData = []
              const { data: getShippingDetails } = await getShippingOrder({
                signal: undefined,
                params: {
                  business_id,
                  order_id: resSales.map((obj) => obj.id).join(','),
                },
              })
              resSales.forEach((obj) => {
                const findShippingDetail = getShippingDetails?.data?.find((item) => item.id === obj.id)
                obj.sales_items.forEach((obj2) => {
                  flattenData.push({
                    created_at: this.$moment(obj.created_at).format('DD MMM YYYY, HH:mm'),
                    order_number: obj.order_number,
                    business_name: obj.business.business_name,
                    channel: obj.channel.sales_channel.name,
                    customer_name: obj.customer_name,
                    sales_items: obj.sales_items.length,
                    sub_total: format(obj.sub_total, 'IDR'),
                    discount_transaction: format(obj.discount ?? 0, 'IDR'),
                    voucher_transaction: format(obj.voucher_amount ?? 0, 'IDR'),
                    loyalty_and_deposit: format(obj.deposit + obj.loyalty_usage, 'IDR'),
                    shipping_cost: format(findShippingDetail?.shipping_cost ?? 0, 'IDR'),
                    revenue_total: format(obj.revenue_total, 'IDR'),
                    courier_name: obj.courier_name,
                    awb: findShippingDetail?.awb ?? '-',
                    order_state: this.$t(`order.filterState.${this.wordingSalesState(obj.sales_state)}`),
                    sales_state: obj.sales_state,
                    payment_state: obj.payment_state,
                    shipping_state: obj.shipping_state,
                    product_title: obj2.title,
                    product_sku: obj2.catalog_sku,
                    product_qty: obj2.item_qty,
                    product_uom: obj2.item_uom,
                    product_price: format(obj2.selling_price, 'IDR'),
                    product_discount: format(obj2.discount ?? 0, 'IDR'),
                    product_sub_total: format(obj2.sub_total, 'IDR'),
                  })
                })
                resolve(flattenData)
              })
            })
          })
          promises.push(
            getDataSalesAndShipping,
          )
        }

        Promise.all(promises)
          .then((data) => {
            const finalData = data?.flat(1)
            if (finalData.length) {
              const rowData = [
                "Tgl Order",
                "No Order",
                "Bisnis",
                "Channel",
                "Pelanggan",
                "Total Item",
                "Sub Total Order",
                "Total Diskon Transaksi",
                "Total Voucher Transaksi",
                "Lain - Lain",
                "Total Biaya Pengiriman",
                "Total Harga",
                "Kurir",
                "No Resi",
                "Status Pesanan",
                "sales_state",
                "payment_state",
                "shipping_state",
                "Nama Produk",
                "Sku",
                "Qty",
                "Uom",
                "Harga",
                "Diskon",
                "Sub Total",
              ]
              finalData.sort((a, b) => {
                return this.$moment(b.paid_at) - this.$moment(a.paid_at);
              })
              const worksheet = XLSX.utils.json_to_sheet(finalData);
              const workbook = XLSX.utils.book_new()
              XLSX.utils.sheet_add_aoa(worksheet, [['Header Order', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Line Item Order'], rowData], { origin: "A1" });
              XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
              var merge = [{ s: {r:0, c:0}, e: {r:0, c:17} }, { s: {r:0, c:18}, e: {r:0, c:24} }]
              if(!worksheet['!merges']) worksheet['!merges'] = []
              worksheet['!merges'] = merge
              XLSX.writeFile(workbook,`salesdata_${this.$moment(filteredData?.startDate).format('MMDDYYYY')}_${this.$moment(filteredData?.endDate).format('MMDDYYYY')}.xlsx`)
              this.$notification.success({
                message: 'Proses export berhasil',
              })
            } else {
              this.$notification.error({
                message: 'Proses export gagal',
                description: 'Data melebihi kapasitas server, silahkan pilih tanggal kembali',
              })
            }
          })
          .catch(() => {
            this.$notification.error({
              message: 'Proses export gagal',
              description: 'Data melebihi kapasitas server, silahkan pilih tanggal kembali',
            })
          })
          .finally(() => {
            this.showModalExportExcel = false
            this.totalExportSalesData = null
            this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
              exportFileNotification: false,
              showModalExportSales: false,
              loadingExport: false,
            })
          })
      } else {
        this.totalExportSalesData = null
        await getSales({
          signal: undefined,
          params: {
            sales_state: value === 'all' || ['SHIPPED', 'DELIVERED'].includes(value) ? undefined : value,
            shipping_states: ['SHIPPED', 'DELIVERED', 'ACCEPTED'].includes(value) ? (shipping_states === 'DELIVERED' ? `${shipping_states},BUYER_ACCEPTED` : shipping_states) : undefined,
            filter_category: title,
            page: 1,
            limit: 1,
            sort_mode: 'DESC',
            workspace_id,
            business_id: data?.selectedBusiness,
            sales_channel_id: data?.selectedSalesChannelId.join(','),
            channel_code: data?.selectedSalesChannelCode,
            channel_id: channelId,
            date_from: data?.startDate,
            date_until: data?.endDate,
          },
        })
        .then(({ data }) => {
          this.totalExportSalesData = data.total_row
        })
        .catch(err => {
          console.error(err)
          if(err.response.data.data === null) {
            this.$notification.error({
              message: 'Data Kosong',
              description: 'Tidak ada data berdasarkan filter tersebut.',
            })
          }
        })
      }
    },
    onCloseModalExportExcel() {
      this.showModalExportExcel = false
      this.totalExportSalesData = null
    },
    onClickAccepted(val) {
      this.dataAccepted = val
      this.showModalAccepted = true
    },
    async onAcceptOrder() {
      let business_id = this.$route.query.business_id
      if (!business_id) {
        business_id = this.$store.state.user?.businessList?.find(
          val => val.warehouse_id === this.$route.query?.warehouse_id,
        )?.business_id
      }

      this.loadingAccept = true
      if (ChannelsConstants.includes(this.dataAccepted.channel?.code)) {
        await processingOrder({
          business_id: this.dataAccepted.business.business_id || business_id,
          processType: 'state',
          id: this.dataAccepted.id,
          channel_code: this.dataAccepted.channel?.code === 'distributor_redeem'
            ? 'distributor'
            : this.dataAccepted.channel?.code,
          new_status: 'ACCEPTED',
          order_number: this.dataAccepted.order_number,
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.onCloseModalAccept()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingAccept = false
        })
      } else {
        await processingOrderState({
          business_id: this.dataAccepted.business.business_id || business_id,
          order_id: this.dataAccepted.id,
          order_number: this.dataAccepted.order_number,
          data: {
            new_status: 'ACCEPTED',
            reason_code: '',
            reason: '',
            oos_line_item_ids: [],
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.onCloseModalAccept()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingAccept = false
        })
      }
    },
    onCloseModalAccept() {
      this.showModalAccepted = false
      this.dataAccepted = {}
    },
    onClickCancelOrder(val) {
      this.dataCancelOrder = val
      this.showModalCancelOrder = true
    },
    async onCancelRequest(val) {
      this.loadingList = true
      if (ChannelsConstants.includes(val.channel?.code)) {
        await processingOrder({
          business_id: val.business.business_id || this.$route.query.business_id,
          processType: 'reject-cancellation-request',
          id: val.id,
          channel_code: val.channel?.code === 'distributor_redeem'
            ? 'distributor'
            : val.channel?.code,
          new_status: 'CANCELED', // CANCELLED
          order_number: val.order_number,
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully reject cancellation')
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingList = false
        })
      } else {
        await processingOrderState({
          business_id: val.business.business_id || this.$route.query.business_id,
          order_id: val.id,
          order_number: val.order_number,
          data: {
            new_status: 'CANCELED',
            reason_code: '',
            reason: '',
            oos_line_item_ids: [],
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.onCloseModalAccept()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingAccept = false
        })
      }
    },
    async onSubmitCancelOrder(values) {
      this.loadingCancelOrder = true
      const { index_reason_code, ...restValues } = values
      const salesChannelCode = this.dataCancelOrder.channel?.code || this.dataCancelOrder.channel?.channel_code
      const salesChannelName = this.dataCancelOrder.channel?.name || this.dataCancelOrder.channel?.channel_title
      if (ChannelsConstants.includes(this.dataCancelOrder.channel?.code)) {
        await processingOrder({
          business_id: this.dataCancelOrder.business.business_id || this.$route.query.business_id,
          processType: 'cancel',
          id: this.dataCancelOrder.id,
          channel_code: this.dataCancelOrder.channel?.code === 'distributor_redeem'
            ? 'distributor'
            : this.dataCancelOrder.channel?.code,
          new_status: 'CANCELED', // CANCELLED
          order_number: this.dataCancelOrder.order_number,
          ...restValues,
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully cancel order')
          // Reset form after success
          const modalCancelOrder = this.$refs.refModalCancelOrder?.form
          modalCancelOrder && modalCancelOrder.resetFields()
          this.closeModalCancelOrder()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingCancelOrder = false
        })
      } else if (LIST_CHANNEL_OFFLINE.includes(salesChannelCode)) {
        cancelOrderState({
          business_id: this.dataCancelOrder.business.business_id || this.$route.query.business_id,
          order_id: this.dataCancelOrder.id,
          order_number: this.dataCancelOrder.order_number,
          data: {
            new_status: 'CANCELED',
            reason_code: `${index_reason_code}`,
            reason: values?.reason,
            channel: {
              id: this.dataCancelOrder?.channel?.id,
              code: salesChannelCode,
              name: salesChannelName,
              sales_channel_id: this.dataCancelOrder?.channel?.sales_channel?.id,
            },
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.closeModalCancelOrder()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingCancelOrder = false
        })
      } else {
        await processingOrderState({
          business_id: this.dataCancelOrder.business.business_id || this.$route.query.business_id,
          order_id: this.dataCancelOrder.id,
          order_number: this.dataCancelOrder.order_number,
          data: {
            new_status: 'CANCELED',
            reason_code: '',
            reason: '',
            oos_line_item_ids: [],
          },
        })
        .then(res => {
          this.$message.success(res?.data?.message || 'Successfully updated state')
          this.closeModalCancelOrder()
          this.getData()
        })
        .catch(err => {
          this.$message.error(err?.response?.data?.message || err.message)
        })
        .finally(() => {
          this.loadingCancelOrder = false
        })
      }
    },
    closeModalCancelOrder() {
      this.showModalCancelOrder = false
      this.dataCancelOrder = {}
    },
    /** @TODO */
    // onPrint() {
    //   // window.print()
    // },
    setDefaultOrderPeriod() {
      return [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()]
    },
    handleChangeSort(sort_mode) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$router.push({ query: { ...this.$route.query, sort_mode } })
      }, 500)
    },
    handleSearch(e) {
      const value = e.target.value
      this.querySearch = value
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const { path, query } = this.$route
        const { workspace_id, business_id, sales_channel_id } = query
        const valTrim = value.trim()
        this.$router.push({
          query: {
            workspace_id,
            business_id,
            // NOTES: set sales_channel_id from query params only page '/sales-channel' | path === '/sales-channel'
            sales_channel_id:
              path?.startsWith('/sales-channel') && sales_channel_id ? sales_channel_id : undefined,
            page: undefined,
            q: valTrim.length ? valTrim : undefined,
          },
        })
      }, 500)
    },
    resetFilter(e) {
      if (e) {
        const { path, query } = this.$route
        const filterModal = this.$refs.filterModal
        filterModal && filterModal.form.resetFields()
        const U = undefined
        this.$router.push({
          query: {
            ...query,
            sort_mode: U,
            business_id_sales: U,
            date_from: U,
            date_until: U,
            // NOTES: set sales_channel_id from query params only page '/sales-channel' | path === '/sales-channel'
            sales_channel_id:
              path?.startsWith('/sales-channel') && query.sales_channel_id
                ? query.sales_channel_id
                : U,
            warehouse_id:
              query.warehouse_id && this.getUserData.restriction_base === 'Warehouse'
                ? query.warehouse_id
                : U,
            customer_name: U,
            courier_name: U,
            order_id: U,
            filter_category: U,
          },
        })
      }
      // Reset state
      this.customerValue = ''
      this.courierValue = ''
      this.businessValue = []
      this.channelValue = []
      this.warehouseValue = []
      this.orderPeriod = this.setDefaultOrderPeriod() // Reset to initial value
    },
    changeCategorieState(state) {
      const { value, title, shipping_states } = state
      const UND = undefined
      this.$router.push({
        query: {
          ...this.$route.query,
          sales_state: value === 'all' || ['SHIPPED', 'DELIVERED'].includes(value) ? UND : value,
          shipping_states: ['SHIPPED', 'DELIVERED', 'ACCEPTED'].includes(value) ? (shipping_states === 'DELIVERED' ? `${shipping_states},BUYER_ACCEPTED` : shipping_states) : UND,
          filter_category: title,
          order_id: UND,
          page: UND,
        },
      })
    },
    changePage(page) {
      this.$router.push({ query: { ...this.$route.query, page } })
    },
    onShowModalFilter() {
      // Reset form modal filter
      const filterModal = this.$refs.filterModal
      filterModal && filterModal.form.resetFields()
      this.showModalFilter = true
    },
    resetRow() {
      this.salesData = []
      this.total_row = 0
    },
    getData: debounce(async function() {
      const { path, query, params } = this.$route
      const {
        business_id,
        business_id_sales,
        sales_channel_id,
        warehouse_id,
        q,
        date_from,
        date_until,
        customer_name,
        courier_name,
        ...restQuery
      } = query // , courier_id

      if (path.includes('/manual-order')) {
        this.categoryState = this.categoryState.filter(f => f.value !== 'CANCELREQUESTED')
      } else if (!this.categoryState.find(f => f.value === 'CANCELREQUESTED')) {
        this.categoryState.splice(3, 0, { value: 'CANCELREQUESTED', title: 'cancelrequested' })
      }

      this.loadingList = true
      this.errorRows = false
      this.querySearch = q || ''
      this.showModalFilter = false // Close modal if back/forward button

      // Initial value from query params
      this.orderPeriod =
        date_from && date_until
          ? [
                this.$moment(date_from).format(),
                this.$moment(date_until).format(),
            ]
          : this.setDefaultOrderPeriod()

      // if (business_id_sales) {
      //   this.businessValue = business_id_sales.split(',')
      // }
      this.businessValue = business_id_sales ? business_id_sales.split(',') : []
      // if (path.includes('/manual-order') && this.loadChannel && sales_channel_id) {
      //   await this.fetchChannel()
      //   // this.channelValue = sales_channel_id?.split(',').map(v => +v)
      // }
      this.channelValue = sales_channel_id
        path.includes('/manual-order') && sales_channel_id ? String(sales_channel_id).split(',').map(v => +v) : []

      // if (this.loadWarehouse && warehouse_id) {
      //   await this.fetchWarehouse()
      //   // this.warehouseValue = warehouse_id.split(',')
      // }
      this.warehouseValue = warehouse_id ? warehouse_id.split(',') : []

      this.customerValue = customer_name || ''
      this.courierValue = courier_name || ''

      // Remove business_id_sales params from url
      if (business_id && business_id_sales) {
        this.$router.replace({ query: { ...query, business_id_sales: undefined } }) // push
      }

      this.controller = new AbortController()

      let salesChannelId = sales_channel_id
      let channelId = +params.id || undefined

      // if (path?.startsWith('/redeem-channel') && +params.id !== 625) {
      if (this.listChannels?.id && path?.startsWith('/redeem-channel') && +params.id !== this.listChannels.id) {
        salesChannelId = this.listChannels.sales_channel.id
        channelId = this.listChannels.id
      }

      await getSales({
        signal: this.controller.signal,
        params: {
          ...restQuery,
          business_id: business_id || business_id_sales,
          page: this.currentPage,
          limit: this.PAGE_SIZE,
          sort_mode: this.sort_mode,
          // channel_id: params.id,
          sales_channel_id: salesChannelId,
          warehouse_id,
          channel_id: channelId,
          q,
          date_from: this.$moment(this.orderPeriod[0]).add(7, 'hours').toISOString(),
          date_until: this.$moment(this.orderPeriod[1]).add(7, 'hours').toISOString(),
          customer_name,
          courier_name,
        },
      })
      .then(({ data: resSales }) => {
        if (resSales) {
          let orderIds = []
          const { data, total_row } = resSales
          this.orderLength = 0

          this.salesData = data && data.length
            ? data.map(d => {
                orderIds.push(d.id)
                this.orderLength = d.order_number && d.order_number.length > this.orderLength
                  ? d.order_number.length
                  : this.orderLength
                if(this.$route.meta.key.includes('sales-channel-kino')) {
                  d.sfa = {
                    order_id: '',
                    created_at: '',
                    response: '',
                    response_body: '',
                    response_code: '',
                  }
                }
                return d
              })
            : []
          this.total_row = total_row
          if(this.$route.meta.key.includes('sales-channel-kino')) {
            this.fetchKinoSfa(orderIds.join())
          }
        } else {
          this.resetRow()
        }
      })
      .catch(err => {
        if (err?.code !== 'ERR_CANCELED') {
          this.errorRows = err.response.status
          this.resetRow()
        }
      })
      .finally(async () => {
        // Get channel data for initial value filter
        if (path.includes('/manual-order') && this.loadChannel && sales_channel_id) {
          await this.fetchChannel()
        }

        // Get warehouse data for initial value filter
        if (this.loadWarehouse && warehouse_id) {
          await this.fetchWarehouse()
        }

        this.controller = null
        this.loadingList = false
      })
    }, 500),
    async fetchKinoSfa(orderIds) {
      this.total_box = 0
      await getKinoSfa({ orderIds })
        .then(({ data: { data: response } }) => {
          response?.forEach(sfa => {
            const index = this.salesData.findIndex(sales => sales.id === sfa.order_id)
            if(index > -1) {
              this.salesData[index].sfa = sfa
              if(sfa.response == '0') {
                this.salesData[index].submitted = false
                this.total_box++
              }
            }
          })
        })
    },
    blurChannel() {
      if (!this.options_channel.length && !this.loadChannel) {
        this.loadChannel = true
      }
    },
    async fetchChannel() {
      if (this.loadChannel) {
        const { business_id } = this.$route.query
        await this.$store
          .dispatch('channel/GETSALESCHANNEL', { business_id })
          .then(() => {
          })
          .catch(err => {
            this.$message.error(err.message)
            document.activeElement.blur()
          })
          .finally(() => {
            this.loadChannel = false
          })
      }
    },
    onBlurWarehouse() {
      // Reset loading state
      if (!this.warehouse_list.length && !this.loadWarehouse) {
        this.loadWarehouse = true
      }
    },
    async fetchWarehouse() {
      if (this.loadWarehouse) {
        const { business_id } = this.$route.query
        await this.$store
          .dispatch('warehouse/GETSALESWAREHOUSE', {
            // limit: this.PAGE_SIZE,
            // page: 1,
            business_id,
          })
          .then(({ data }) => {
            this.warehouse_list = data || []
          })
          .catch((err) => {
            this.$message.error(err.message)
            document.activeElement.blur()
          })
          .finally(() => {
            this.loadWarehouse = false
          })
      }
    },
    async onOpenCollapse(item) {
      await this.fetchWarehouse()
      this.salesData.forEach(f => {
        if (f.id === item.id) {
          // Get & Set warehouse name
          const nameWarehouse = this.warehouse_list.find(f => f.id === item.warehouse_id)
          if (nameWarehouse) {
            item.warehouseName = nameWarehouse.name
            item.warehouseAddress = nameWarehouse.address
          }
        }
      })
    },
    checkboxSelectAllSubmitted(event) {
      this.checked_all = event.target.checked
      this.salesData.forEach((item, index) => {
        if(this.$route.meta.key.includes('sales-channel-kino') && item?.sfa?.response == '0') {
          this.handleItemSubmitted({
            index,
            checked: event.target.checked,
            id: item.id,
          })
        }
      })
    },
    handleSfaDetail(value) {
      this.sfaInfo = {
        visible: true,
        order_number: value.order_number,
        description: value.response_body,
      }
    },
    handleItemSubmitted(obj) {
      this.salesData[obj.index].submitted = obj.checked
      const index = this.tempSales.findIndex(sales => sales === obj.id)
      if(obj.checked && index < 0) {
        this.tempSales.push(obj.id)
      } else if(!obj.checked && index > -1) {
        this.tempSales.splice(index, 1)
      }
      
      this.indeterminate = this.tempSales.length > 0 && this.tempSales.length < this.total_box
      this.checked_all = this.tempSales.length == this.total_box
    },
    async handleSubmitSFA() {
      this.loadingList = true
      await resendKinoSfa({
        business_id: this.businessId,
        data: { order_ids: this.tempSales },
      })
      .then(() => this.finishSubmitSFA('success', ''))
      .catch(err => this.finishSubmitSFA('failed', err?.response?.data?.message || ''))
    },
    finishSubmitSFA: debounce(function(status, description) {
      const type = status === 'failed' ? 'error' : 'success'
      this.$notification[type]({
        message: `Resend SFA ${this.$t(`utils.${status}`)}`,
        description,
      })
      if(status === 'success') {
        this.indeterminate = false
        this.checked_all = false
        this.tempSales = []
        this.getData()
      } else {
        this.loadingList = false
      }
    },5000),
  },
}
</script>

<style lang="scss" scoped>
.row-calc > .col {
  padding-left: 9px;
  padding-right: 9px;
  flex-basis: calc(100% / 6); // 100% / 8
  max-width: calc(100% / 6);
  font-size: 13px;
}

.col-10p {
  flex-basis: 10%;
  max-width: 10%;
  font-size: 13px;
}

.salesindex {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;

  .categories {
    .list {
      color: #999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 14px;
      min-height: 30px;
      background: #fff;
    }

    .list:not(:focus) {
      border-color: #999;
    }

    .active {
      background: #4d4d4d;
      color: #fff;
      border-color: #4d4d4d;
    }

    .list:hover:not(.active) {
      border-color: #4d4d4d;
      color: #4d4d4d;
    }
  }

  .active {
    background: #0559cb;
    color: #fff;
    border: 1px solid #0559cb;
  }
}
</style>

<style lang="scss">
.skeleton-total-sales ul {
  margin: 0;
}
.skeleton-total-sales li {
  height: 18px !important;
  border: 1px solid #ddd;
}
.w-8 {
  flex-basis: 4% !important;
  max-width: 4% !important;
  -ms-flex-preferred-size: 4% !important;
}
.fulfillment-checkbox .ant-checkbox-inner {
  border-color: #999 !important;
}

.avatar-uploader > .ant-upload,
.ant-upload-list-picture-card-container,
.ant-upload-list-picture-card .ant-upload-list-item {
  width: 150px !important;
  height: 150px !important;
  margin: 0 !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px !important;
  color: #999 !important;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px !important;
  color: #666 !important;
}
.ant-upload-list-item-name {
  white-space: inherit !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  font-size: 48px !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions i {
  width: 24px !important;
  font-size: 24px !important;
}
.btn-download {
  margin: 0;
  padding: 0;
  border: none;

  .ant-upload {
    width: 100% !important;
  }
}
.btn-download:disabled div,
.btn-download[disabled] div,
.btn-download:disabled span,
.btn-download[disabled] span {
  cursor: not-allowed;
}
.verified-alert .ant-alert-message {
  font-size: 13px !important;
  font-weight: 500 !important;
}
.log-timeline .log-current {
  color: #f5222e;
}
.log-timeline .ant-timeline-item-head-red {
  background-color: #f5222e !important;
}
.log-timeline .ant-timeline-item-head-gray {
  background-color: #c8c4db !important;
}
</style>
